import { ThemeOptions } from '@mui/material/styles'

const paletteTheme = {
    primary: {
        main: '#297585',
        light: '#00c853',
        dark: '#8e24aa',
        contrastText: '#f8f8f7',
    },
    secondary: {
        main: '#2a2a25',
        contrastText: '#f8f8f7',
        light: '#68665a',
        dark: '#1d1d1c',
    },
    background: {
        default: '#f8f8f7',
        secondary: '#F5F4EC',
    },
    text: {
        primary: '#31312F',
        secondary: '#6C6B68',
        disabled: '#8b8878',
        hint: '#68665a',
        link: '#175CD3',
        primaryLink: '#2653d9',
    },
    error: {
        main: '#c93c25',
        light: '#ecb9b1',
        dark: '#892919',
        contrastText: '#f8f8f7',
    },
    warning: {
        main: '#dc6803',
        light: '#f2c9a4',
        dark: '#964702',
        contrastText: '#f8f8f7',
    },
    info: {
        main: '#2b94c1',
        light: '#d5eaf3',
        dark: '#1d6583',
        contrastText: '#f8f8f7',
    },
    success: {
        main: '#669f28',
        light: '#e6f4d7',
        dark: '#3f621a',
        contrastText: '#f8f8f7',
    },
    divider: '#dcdaca',
}
// For now light mode and dark mode are the same.
// Just setting up infrastructure
export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
    palette: {
        mode,
        ...(mode === 'light' ? paletteTheme : paletteTheme),
    },
    typography: {
        fontFamily: ['"Helvetica Neue"', '"Helvetica"', '"Nimbus Sans"', '"Arial"', '"sans-serif"'].join(','),
        fontSize: 16,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        fontWeightRegular: 400,
        h1: {
            fontSize: 32,
            fontWeight: 600,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: 24,
            fontWeight: 600,
        },
        h3: {
            fontSize: '1rem',
            lineHeight: 1.25,
            fontWeight: 600,
        },
        body2: {
            lineHeight: 1.5,
        },
        h4: {
            fontWeight: 600,
            fontSize: '0.875rem',
            lineHeight: 1.25,
        },
        h5: {
            fontSize: '0.85rem',
            fontWeight: 600,
            lineHeight: 1.25,
        },
        h6: {
            fontSize: '0.875rem',
            lineHeight: 1.25,
        },
        body1: {
            fontSize: '1.125rem',
        },

        bodySmall: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },

        headLineSmall: {
            fontSize: '0.875rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        headLineLarge: {
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        headLineXL: {
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        labelSmall: {
            fontSize: '0.875rem',
            fontWeight: 400,
            letterSpacing: '0.01em',
            lineHeight: 1.2,
        },
        labelSmallBold: {
            fontSize: '0.875rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0.01em',
        },
        labelMedium: {
            fontSize: '1rem',
            lineHeight: 1.25,
            letterSpacing: '0.01em',
        },
        labelMediumBold: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.01em',
        },
        labelXSmall: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.01em',
        },
        button: {
            lineHeight: 1.2,
            fontWeight: 400,
            textTransform: 'none',
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    color: '#2653D9',
                    '&:visited': {
                        color: ' #98158B',
                    },
                    '&:hover': {
                        color: '#07b',
                    },
                    '&:active': {
                        color: '#07b',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    color: '#2653D9',
                    '&:visited': {
                        color: ' #98158B',
                    },
                    '&:hover': {
                        color: '#07b',
                    },
                    '&:active': {
                        color: '#07b',
                    },
                },
            },
        },
    },
})
