import * as React from 'react'
import { Trans } from '@lingui/macro'

import { useLocalisation, Language } from '../../i18n'
import { useLogin } from '../../login'
import { Link } from '../link'
import { Banner } from '../banner'

const languageNames: { [k in Language]: React.ReactElement } = {
    en: <Trans>English</Trans>,
    de: <Trans>German</Trans>,
    es: <Trans>Spanish</Trans>,
    fr: <Trans>French</Trans>,
    it: <Trans>Italian</Trans>,
    ja: <Trans>Japanese</Trans>,
    ko: <Trans>Korean</Trans>,
    'pt-BR': <Trans>Portuguese</Trans>,
    ru: <Trans>Russian</Trans>,
}

export function LanguageBanner(): React.ReactElement | null {
    const { redirectedFrom, language, localiseURL, changeLanguage } = useLocalisation()
    const { loggedIn } = useLogin()

    const [dismissed, setDismissed] = React.useState(false)

    React.useEffect(
        function (): void {
            setDismissed(false)
        },
        [redirectedFrom],
    )

    if (dismissed || !redirectedFrom) {
        return null
    }

    function handleRevert(evt: React.MouseEvent<HTMLAnchorElement>): void {
        evt.preventDefault()

        if (redirectedFrom === null) {
            return
        }
        changeLanguage(redirectedFrom)
    }

    function handleDismiss(): void {
        setDismissed(true)
    }

    const from = languageNames[redirectedFrom]
    const to = languageNames[language]
    const originalURL = localiseURL(location.pathname, redirectedFrom)

    return (
        <Banner onDismiss={handleDismiss}>
            <Trans>You clicked on the {from} version of this page.</Trans>{' '}
            <Trans>
                <a href={originalURL} onClick={handleRevert}>
                    Click here
                </a>{' '}
                if you would like to view it in {from} instead of your default language ({to}).
            </Trans>{' '}
            {loggedIn && (
                <Trans key='B'>
                    You can update your default language preference in your{' '}
                    <Link href='/settings/user'>General Settings</Link>.
                </Trans>
            )}
        </Banner>
    )
}
