import * as React from 'react'

export type Message = string | React.ReactElement

export type Err = {
    id: number
    message: Message
    context?: Message
}

type ErrorsContext = {
    errors: Err[]
    onError: (err: Error | Message, context?: Message) => void
    dismiss: (id: number) => void
}

const context = React.createContext<ErrorsContext>({
    errors: [],
    onError: () => undefined,
    dismiss: () => undefined,
})

type Props = {
    initialErrors?: (Error | Message)[]
    children?: React.ReactElement
}

export function ErrorsProvider(props: Props): React.ReactElement {
    const { children, initialErrors = [] } = props
    const [errors, setErrors] = React.useState<Err[]>(initialErrors.map((err: Error | Message): Err => toErr(err)))

    const value = React.useMemo(
        () => ({
            errors,
            onError(error: Error | Message, context?: Message): void {
                setErrors((errors: Err[]): Err[] => [...errors, toErr(error, context)])
            },
            dismiss(id: number): void {
                setErrors((errors: Err[]): Err[] => errors.filter((err: Err): boolean => err.id !== id))
            },
        }),
        [errors],
    )

    return <context.Provider value={value}>{children}</context.Provider>
}

function toErr(err: Error | Message, context?: Message): Err {
    return {
        id: Math.random(),
        message: message(err),
        context,
    }
}

function message(err: Error | Message): Message {
    if (err instanceof Error) {
        return err.message
    }

    if (typeof err === 'string') {
        return err
    }

    return err
}

export function useErrors(): ErrorsContext {
    return React.useContext(context)
}
// this comment will be removed when I merge in changes to the errorSection placement
export function useErrorHandler(context: Message): (err: Error | Message) => void {
    const { onError } = useErrors()

    return function (err: Error | Message): void {
        onError(err, context)
    }
}
