import * as React from 'react'
import Logo from '../lib/components/logo'

type Props = {
    message?: string | React.ReactNode | undefined
    status?: number
    requestId?: string
}

export function FatalErrorPage(props: Props): React.ReactElement {
    const { message, status, requestId } = props

    const main = {
        fontFamily: 'Helvetica',
        padding: '1em',
        paddingTop: '4em',
    }

    const head = {
        height: 56,
        background: '#333',
        position: 'absolute' as const,
        top: 0,
        left: 0,
        right: 0,
    }

    const logo = {
        width: 112,
        marginTop: 8,
        marginLeft: 18,
    }

    return (
        <main style={main}>
            <div style={head}>
                <Logo style={logo} />
            </div>
            <h1 style={{ fontSize: '1em' }}>{status ?? <>{status}:</>} A fatal error occurred</h1>
            <pre>{message}</pre>
            {requestId && <pre>Request ID: {requestId}</pre>}
            <p>
                You can <a href='https://support.discogs.com/hc/requests/new'>send us a support request</a> or{' '}
                <a href='/'>return to the homepage</a>.
            </p>
        </main>
    )
}
